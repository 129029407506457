import React, {Fragment, useEffect, useState} from "react";
import man from "../../../assets/images/dashboard/user.png";
import { User, Mail, Lock, Settings, LogOut } from "react-feather";
import {useNavigate} from "react-router-dom";
import {setIsLoggedIn, setUserInfo} from "../../../redux/user/reducer";
import {useDispatch} from "react-redux";

const UserMenu = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
    <Fragment>
      <li className="onhover-dropdown">
        <div className="d-flex align-items-center">
          <img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" src={man} alt="header-user" />
          <div className="dotted-animation">
              {/*<span className="animate-circle"></span>
            <span className="main-circle"></span>*/}
          </div>
        </div>
        <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
          {/*<li>
            <a href="#javascript">
              <User />
              프로필 편집
            </a>
          </li>
          <li>
            <a href="#javascript">
              <Settings />
              설정
            </a>
          </li>*/}
          <li>
            <a href="#javascript" onClick={e => {
                e.preventDefault();

                dispatch(setIsLoggedIn(false));
                dispatch(setUserInfo({}));
                localStorage.removeItem('token');
                localStorage.removeItem('auth_data');
                navigate('/login');
            }}>
              <LogOut /> 로그아웃
            </a>
          </li>
        </ul>
      </li>
    </Fragment>
  );
};

export default UserMenu;
