import { Database, Headphones, Home, User} from "react-feather";

export const MENUITEMS = [
  {
    title: "사용자 관리",
    icon: User,
    type: "link",
    path: "/dashboard/user",
    active: false,
  },
  {
    title: "사용자 개통 내역",
    icon: Headphones,
    path: "/dashboard/order",
    type: "link",
    active: false,
  },
  {
    title: "대리판매자 개통내역",
    icon: Headphones,
    path: "/dashboard/resellerOrder",
    type: "link",
    active: false,
    is_merchant: true,
  },
  {
    title: "포인트 증감 내역",
    icon: Database,
    path: "/dashboard/pointHistory",
    type: "link",
    active: false,
  },

];
