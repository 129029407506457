import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "./i18n";
import * as serviceWorker from "./serviceWorker";

// ** Import custom components for redux **
import { Provider } from "react-redux";
import store from "./store";
import MainRoutes from "./routes";
import {QueryClient, QueryClientProvider} from "react-query";
import {Helmet} from "react-helmet";

// setup fake backend

const Root = () => {
  const client = new QueryClient();

  return (
    <div className="App">
        <QueryClientProvider client={client}>
            <Provider store={store}>
                <Helmet titleTemplate="%s - FastN Dashboard" />
                <MainRoutes />
            </Provider>
        </QueryClientProvider>
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);

serviceWorker.unregister();
