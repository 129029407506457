import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // we init with resources
        resources: {
            en: {
                translations: {
                    "Dashboard": "Dashboard",
                    "Default": "Default",
                    "E-Commerce": "E-Commerce",
                    "University": "University",
                    "Bitcoin": "Bitcoin",
                    "Server": "Server",
                    "Project": "Project",
                    "Widgets": "Widgets",
                    "General widget": "General widget",
                    "Chart widget": "Chart widget",
                    "UI-Elements": "UI-Elements",
                    "Avatars": "Avatars",
                    "Breadcrumb": "Breadcrumb",
                    "Grid": "Grid",
                    "Helper-Classes": "Helper-Classes",
                    "List": "List",
                    "Ribbons": "Ribbons",
                    "Shadow": "Shadow",
                    "Spinner": "Spinner",
                    "State-color": "State-color",
                    "Steps": "Steps",
                    "Tag and Pills": "Tag and Pills",
                    "Typography": "Typography",
                    "Base": "Base",
                    "Accordion": "Accordion",
                    "Alert": "Alert",
                    "Buttons": "Buttons",
                    "Carousel": "Carousel",
                    "Collapse": "Collapse",
                    "Datepicker": "Datepicker",
                    "Dropdown": "Dropdown",
                    "Modal": "Modal",
                    "Pagination": "Pagination",
                    "Popover": "Popover",
                    "Progressbar": "Progressbar",
                    "Rating": "Rating",
                    "Tabset": "Tabset",
                    "Timepicker": "Timepicker",
                    "Tooltip": "Tooltip",
                    "Typeahead": "Typeahead",
                    "Advance": "Advance",
                    "Drag and Drop": "Drag and Drop",
                    "Dropzone": "Dropzone",
                    "Image Cropper": "Image Cropper",
                    "Ngx Toastr": "Ngx Toastr",
                    "Owl-Carousel": "Owl-Carousel",
                    "Range Slider": "Range Slider",
                    "Scrollable": "Scrollable",
                    "Sticky": "Sticky",
                    "SweetAlert": "SweetAlert",
                    "Tour": "Tour",
                    "Upload": "Upload",
                    "Icons": "Icons",
                    "Flag icon": "Flag icon",
                    "Fontawesome Icon": "Fontawesome Icon",
                    "Ico Icon": "Ico Icon",
                    "Themify Icon": "Themify Icon",
                    "Feather Icon": "Feather Icon",
                    "Whether Icon": "Whether Icon",
                    //"Buttons": "Buttons",
                    "Default Style": "Default Style",
                    "Flat Style": "Flat Style",
                    "Edge Style": "Edge Style",
                    "Raised Style": "Raised Style",
                    "Button Group": "Button Group",
                    "Forms": "Forms",
                    "Form Controls": "Form Controls",
                    "Form Validation": "Form Validation",
                    "Base Inputs": "Base Inputs",
                    "Checkbox & Radio": "Checkbox & Radio",
                    "Input Groups": "Input Groups",
                    "Mega Options": "Mega Options",
                    "Form Default": "Form Default",
                    "Ngx Wizard": "Ngx Wizard",
                    "Tables": "Tables",
                    "Bootstrap Tables": "Bootstrap Tables",
                    "Basic Table": "Basic Table",
                    "Sizing Table": "Sizing Table",
                    "Border Table": "Border Table",
                    "Styling Table": "Styling Table",
                    "Ngx-Datatables": "Ngx-Datatables",
                    //"Basic Table": "Basic Table",
                    "Editing Table": "Editing Table",
                    "Filter Table": "Filter Table",
                    "Fullscreen Table": "Fullscreen Table",
                    "Paging Table": "Paging Table",
                    "Selection": "Selection",
                    "Smart Table": "Smart Table",
                    "Cards": "Cards",
                    "Basic Card": "Basic Card",
                    "Creative Card": "Creative Card",
                    "Tabbed Card": "Tabbed Card",
                    "Draggable Card": "Draggable Card",
                    "Timeline": "Timeline",
                    "Timeline 1": "Timeline 1",
                    "Timeline 2": "Timeline 2",
                    "Charts": "Charts",
                    "Google Chart": "Google Chart",
                    "Chartjs": "Chartjs",
                    "Chartist": "Chartist",
                    "Ngx-Chart": "Ngx-Chart",
                    "Map": "Map",
                    "Google Map": "Google Map",
                    "Leaflet Map": "Leaflet Map",
                    "Editor": "Editor",
                    "Users": "Users",
                    "Users Profile": "Users Profile",
                    "Users Edit": "Users Edit",
                    "Users Cards": "Users Cards",
                    "Calender": "Calender",
                    "Blog": "Blog",
                    "Blog Details": "Blog Details",
                    "Blog Single": "Blog Single",
                    "Email": "Email",
                    "Chat": "Chat",
                    "Social App": "Social App",
                    "Job-Search": "Job-Search",
                    "Card View": "Card View",
                    "List View": "List View",
                    "Job Detail": "Job Detail",
                    "Apply": "Apply",
                    "Learning": "Learning",
                    "Learning List": "Learning List",
                    "Detail Course": "Detail Course",
                    "FAQ": "FAQ",
                    "Knowledgebase": "Knowledgebase",
                    "Support Ticket": "Support Ticket",
                    "To-Do": "To-Do",
                    "Ecommerce": "Ecommerce",
                    "Product": "Product",
                    "Product page": "Product page",
                    "Product list": "Product list",
                    "Payment Details": "Payment Details",
                    "Invoice": "Invoice",
                    "Order History": "Order History",
                    "Pricing": "Pricing",
                    "Sample page": "Sample page",
                    "Search Pages": "Search Pages",
                    "Error Page": "Error Page",
                    "Error 400": "Error 400",
                    "Error 401": "Error 401",
                    "Error 403": "Error 403",
                    "Error 404": "Error 404",
                    "Error 500": "Error 500",
                    "Error 503": "Error 503",
                    "Authentication": "Authentication",
                    "Login Simple": "Login Simple",
                    "Login with Bg Image": "Login with Bg Image",
                    "Login with Bg video": "Login with Bg video",
                    "Register Simple": "Register Simple",
                    "Register with Bg Image": "Register with Bg Image",
                    "Register with Bg video": "Register with Bg video",
                    "Unlock User": "Unlock User",
                    "Forget Password": "Forget Password",
                    "Reset Password": "Reset Password",
                    "Coming Soon": "Coming Soon",
                    "Coming Simple": "Coming Simple",
                    "Coming with Bg Image": "Coming with Bg Image",
                    "Coming with Bg video": "Coming with Bg video",
                    "Maintenance": "Maintenance"
                }
            },
            es: {
                translations: {
                    "Dashboard": "Tablero",
                    "Default": "Defecto",
                    "E-Commerce": "Comercio electronico",
                    "University": "Universidad",
                    "Bitcoin": "Bitcoin",
                    "Server": "Servidor",
                    "Project": "Proyecto",
                    "Widgets": "Widgets",
                    "General widget": "Widget general",
                    "Chart widget": "Widget de gráfico",
                    "UI-Elements": "UI-Elements",
                    "Avatars": "Avatares",
                    "Breadcrumb": "Migaja de pan",
                    "Grid": "Cuadrícula",
                    "Helper-Classes": "Clases de ayuda",
                    "List": "Lista",
                    "Ribbons": "Cintas",
                    "Shadow": "Sombra",
                    "Spinner": "Hilandero",
                    "State-color": "Color del estado",
                    "Steps": "Pasos",
                    "Tag and Pills": "Etiqueta y pastillas",
                    "Typography": "Tipografía",
                    "Base": "Base",
                    "Accordion": "Acordeón",
                    "Alert": "Alerta",
                    "Buttons": "Botones",
                    "Carousel": "Carrusel",
                    "Collapse": "Colapso",
                    "Datepicker": "Selector de fechas",
                    "Dropdown": "Desplegable",
                    "Modal": "Modal",
                    "Pagination": "Paginación",
                    "Popover": "Popover",
                    "Progressbar": "Barra de progreso",
                    "Rating": "Clasificación",
                    "Tabset": "Pastillas",
                    "Timepicker": "Timepicker",
                    "Tooltip": "Información sobre herramientas",
                    "Typeahead": "Typeahead",
                    "Advance": "Avanzar",
                    "Drag and Drop": "Arrastrar y soltar",
                    "Dropzone": "Zona de descenso",
                    "Image Cropper": "Recortadora de imagen",
                    "Ngx Toastr": "Ngx Toastr",
                    "Owl-Carousel": "Búho-carrusel",
                    "Range Slider": "Control deslizante de rango",
                    "Scrollable": "Desplazable",
                    "Sticky": "Pegajoso",
                    "SweetAlert": "SweetAlert",
                    "Tour": "Excursión",
                    "Upload": "Subir",
                    "Icons": "Iconos",
                    "Flag icon": "Icono de bandera",
                    "Fontawesome Icon": "Icono de Fontawesome",
                    "Ico Icon": "Icono de Ico",
                    "Themify Icon": "Icono de Themify",
                    "Feather Icon": "Icono de pluma",
                    "Whether Icon": "Si icono",
                    //"Buttons": "Botones",
                    "Default Style": "Estilo por Defecto",
                    "Flat Style": "Estilo plano",
                    "Edge Style": "Estilo de borde",
                    "Raised Style": "Estilo elevado",
                    "Button Group": "Grupo de botones",
                    "Forms": "Formas",
                    "Form Controls": "Controles de formulario",
                    "Form Validation": "Validación de formularios",
                    "Base Inputs": "Entradas base",
                    "Checkbox & Radio": "Casilla de verificación y radio",
                    "Input Groups": "Grupos de entrada",
                    "Mega Options": "Mega Opciones",
                    "Form Default": "Forma predeterminada",
                    "Ngx Wizard": "Ngx Wizard",
                    "Tables": "Mesas",
                    "Bootstrap Tables": "Mesas de Bootstrap",
                    "Basic Table": "Tabla basica",
                    "Sizing Table": "Tabla de tallas",
                    "Border Table": "Tabla de borde",
                    "Styling Table": "Mesa de peluquería",
                    "Ngx-Datatables": "Ngx-Datatable",
                    //"Basic Table": "Tabla basica",
                    "Editing Table": "Mesa de edición",
                    "Filter Table": "Tabla de filtro",
                    "Fullscreen Table": "Tabla de pantalla completa",
                    "Paging Table": "Mesa de paginación",
                    "Selection": "Selección",
                    "Smart Table": "Mesa inteligente",
                    "Cards": "Tarjetas",
                    "Basic Card": "Tarjeta basica",
                    "Creative Card": "Tarjeta creativa",
                    "Tabbed Card": "Tarjeta con pestañas",
                    "Draggable Card": "Tarjeta Arrastrable",
                    "Timeline": "Línea de tiempo",
                    "Timeline 1": "Línea de tiempo 1",
                    "Timeline 2": "Línea de tiempo 2",
                    "Charts": "Cartas",
                    "Google Chart": "Google Chart",
                    "Chartjs": "Chartjs",
                    "Chartist": "Chartista",
                    "Ngx-Chart": "Ngx-gráfico",
                    "Map": "Mapa",
                    "Google Map": "Mapa de Google",
                    "Leaflet Map": "Mapa del folleto",
                    "Editor": "Editor",
                    "Users": "Usuarios",
                    "Users Profile": "Perfil de usuario",
                    "Users Edit": "Editar usuarios",
                    "Users Cards": "Tarjetas de usuario",
                    "Calender": "Calandrar",
                    "Blog": "Blog",
                    "Blog Details": "Detalles del blog",
                    "Blog Single": "Blog Single",
                    "Email": "Email",
                    "Chat": "Charla",
                    "Social App": "Aplicación social",
                    "Job-Search": "Búsqueda de trabajo",
                    "Card View": "Vista de tarjeta",
                    "List View": "Vista de la lista",
                    "Job Detail": "Detalles del trabajo",
                    "Apply": "Aplicar",
                    "Learning": "Aprendizaje",
                    "Learning List": "Lista de aprendizaje",
                    "Detail Course": "Curso de detalle",
                    "FAQ": "Preguntas más frecuentes",
                    "Knowledgebase": "Base de conocimientos",
                    "Support Ticket": "Boleto de soporte",
                    "To-Do": "Que hacer",
                    "Ecommerce": "Comercio electrónico",
                    "Product": "Producto",
                    "Product page": "Página del producto",
                    "Product list": "Lista de productos",
                    "Payment Details": "Detalles del pago",
                    "Invoice": "Factura",
                    "Order History": "Historial de pedidos",
                    "Pricing": "Precios",
                    "Sample page": "Página de Ejemplo",
                    "Search Pages": "Páginas de búsqueda",
                    "Error Page": "Página de error",
                    "Error 400": "Error 400",
                    "Error 401": "Error 401",
                    "Error 403": "Error 403",
                    "Error 404": "Error 404",
                    "Error 500": "Error 500",
                    "Error 503": "Error 503",
                    "Authentication": "Autenticación",
                    "Login Simple": "Inicio de sesión simple",
                    "Login with Bg Image": "Iniciar sesión con Bg Image",
                    "Login with Bg video": "Iniciar sesión con Bg video",
                    "Register Simple": "Registrarse Simple",
                    "Register with Bg Image": "Registrarse con Bg Image",
                    "Register with Bg video": "Registrarse en Bg video",
                    "Unlock User": "Desbloquear usuario",
                    "Forget Password": "Contraseña olvidada",
                    "Reset Password": "Restablecer la contraseña",
                    "Coming Soon": "Próximamente",
                    "Coming Simple": "Coming Simple",
                    "Coming with Bg Image": "Viniendo con Bg Image",
                    "Coming with Bg video": "Viniendo con Bg video",
                    "Maintenance": "Mantenimiento"
                }
            },
            fr: {
                translations: {
                    "Dashboard": "Tableau de bord",
                    "Default": "Défaut",
                    "E-Commerce": "E-Commerce",
                    "University": "Université",
                    "Bitcoin": "Bitcoin",
                    "Server": "Serveur",
                    "Project": "Projet",
                    "Widgets": "Widgets",
                    "General widget": "Widget général",
                    "Chart widget": "Widget graphique",
                    "UI-Elements": "Eléments d'interface utilisateur",
                    "Avatars": "Avatars",
                    "Breadcrumb": "Miette de pain",
                    "Grid": "la grille",
                    "Helper-Classes": "Classes d'assistants",
                    "List": "liste",
                    "Ribbons": "Rubans",
                    "Shadow": "Ombre",
                    "Spinner": "Fileur",
                    "State-color": "Couleur d'état",
                    "Steps": "Pas",
                    "Tag and Pills": "Tag et pilules",
                    "Typography": "Typographie",
                    "Base": "Base",
                    "Accordion": "Accordéon",
                    "Alert": "Alerte",
                    "Buttons": "Boutons",
                    "Carousel": "Carrousel",
                    "Collapse": "Effondrer",
                    "Datepicker": "Sélecteur de date",
                    "Dropdown": "Menu déroulant",
                    "Modal": "Modal",
                    "Pagination": "Pagination",
                    "Popover": "Popover",
                    "Progressbar": "Barre de progression",
                    "Rating": "Évaluation",
                    "Tabset": "Tabset",
                    "Timepicker": "Timepicker",
                    "Tooltip": "Info-bulle",
                    "Typeahead": "Tête de caractères",
                    "Advance": "Avance",
                    "Drag and Drop": "Glisser déposer",
                    "Dropzone": "Zone de largage",
                    "Image Cropper": "Cropper Image",
                    "Ngx Toastr": "Ngx Toastr",
                    "Owl-Carousel": "Hibou-carrousel",
                    "Range Slider": "Range Slider",
                    "Scrollable": "Scrollable",
                    "Sticky": "Gluant",
                    "SweetAlert": "SweetAlert",
                    "Tour": "Tour",
                    "Upload": "Télécharger",
                    "Icons": "Les icônes",
                    "Flag icon": "Icône de drapeau",
                    "Fontawesome Icon": "Icône Fontawesome",
                    "Ico Icon": "Ico Icon",
                    "Themify Icon": "Icône Themify",
                    "Feather Icon": "Icône de la plume",
                    "Whether Icon": "Si icône",
                    //"Buttons": "Boutons",
                    "Default Style": "Style par défaut",
                    "Flat Style": "Style plat",
                    "Edge Style": "Style de bord",
                    "Raised Style": "Style Surélevé",
                    "Button Group": "Groupe de boutons",
                    "Forms": "Formes",
                    "Form Controls": "Contrôles de formulaire",
                    "Form Validation": "Validation du formulaire",
                    "Base Inputs": "Entrées de base",
                    "Checkbox & Radio": "Case à cocher et radio",
                    "Input Groups": "Groupes d'entrées",
                    "Mega Options": "Mega Options",
                    "Form Default": "Formulaire par défaut",
                    "Ngx Wizard": "Assistant Ngx",
                    "Tables": "les tables",
                    "Bootstrap Tables": "Tables Bootstrap",
                    "Basic Table": "Table de base",
                    "Sizing Table": "Tableau de dimensionnement",
                    "Border Table": "Table de frontière",
                    "Styling Table": "Table de coiffure",
                    "Ngx-Datatables": "Ngx-Datatables",
                    //"Basic Table": "Table de base",
                    "Editing Table": "Tableau d'édition",
                    "Filter Table": "Table de filtrage",
                    "Fullscreen Table": "Tableau plein écran",
                    "Paging Table": "Table de pagination",
                    "Selection": "Sélection",
                    "Smart Table": "Smart Table",
                    "Cards": "Cartes",
                    "Basic Card": "Carte de base",
                    "Creative Card": "Carte créative",
                    "Tabbed Card": "Carte à onglets",
                    "Draggable Card": "Carte glissable",
                    "Timeline": "Chronologie",
                    "Timeline 1": "Chronologie 1",
                    "Timeline 2": "Chronologie 2",
                    "Charts": "Graphiques",
                    "Google Chart": "Google Chart",
                    "Chartjs": "Chartjs",
                    "Chartist": "Chartiste",
                    "Ngx-Chart": "Ngx-Chart",
                    "Map": "Carte",
                    "Google Map": "Google Map",
                    "Leaflet Map": "Carte du dépliant",
                    "Editor": "Éditeur",
                    "Users": "Utilisateurs",
                    "Users Profile": "Profil des utilisateurs",
                    "Users Edit": "Utilisateurs Modifier",
                    "Users Cards": "Cartes Utilisateurs",
                    "Calender": "Calandre",
                    "Blog": "Blog",
                    "Blog Details": "Détails du blog",
                    "Blog Single": "Blog Single",
                    "Email": "Email",
                    "Chat": "Bavarder",
                    "Social App": "App sociale",
                    "Job-Search": "Recherche d'emploi",
                    "Card View": "Vue carte",
                    "List View": "Voir la liste",
                    "Job Detail": "Détail du travail",
                    "Apply": "Appliquer",
                    "Learning": "Apprentissage",
                    "Learning List": "Liste d'apprentissage",
                    "Detail Course": "Cours de détail",
                    "FAQ": "FAQ",
                    "Knowledgebase": "Base de connaissances",
                    "Support Ticket": "Ticket de support",
                    "To-Do": "Faire",
                    "Ecommerce": "Commerce électronique",
                    "Product": "Produit",
                    "Product page": "Page produit",
                    "Product list": "Liste de produits",
                    "Payment Details": "Détails de paiement",
                    "Invoice": "Facture d'achat",
                    "Order History": "Historique des commandes",
                    "Pricing": "Prix",
                    "Sample page": "Page d'exemple",
                    "Search Pages": "Pages de recherche",
                    "Error Page": "Page d'erreur",
                    "Error 400": "Erreur 400",
                    "Error 401": "Erreur 401",
                    "Error 403": "Erreur 403",
                    "Error 404": "Erreur 404",
                    "Error 500": "Erreur 500",
                    "Error 503": "Erreur 503",
                    "Authentication": "Authentification",
                    "Login Simple": "Login Simple",
                    "Login with Bg Image": "Connexion avec Bg Image",
                    "Login with Bg video": "Connexion avec Bg video",
                    "Register Simple": "Inscrivez-vous simple",
                    "Register with Bg Image": "S'inscrire avec Bg Image",
                    "Register with Bg video": "S'inscrire avec Bg video",
                    "Unlock User": "Déverrouiller l'utilisateur",
                    "Forget Password": "Mot de passe oublié",
                    "Reset Password": "réinitialiser le mot de passe",
                    "Coming Soon": "Arrive bientôt",
                    "Coming Simple": "Venir simple",
                    "Coming with Bg Image": "Venir avec Bg Image",
                    "Coming with Bg video": "Venir avec Bg video",
                    "Maintenance": "Entretien"
                }
            },
            pt: {
                translations: {
                    "Dashboard": "painel de controle",
                    "Default": "Padrão",
                    "E-Commerce": "Comércio Eletrônico",
                    "University": "Universidade",
                    "Bitcoin": "Bitcoin",
                    "Server": "Servidor",
                    "Project": "Projeto",
                    "Widgets": "Widgets",
                    "General widget": "Widget Geral",
                    "Chart widget": "Widget gráfico",
                    "UI-Elements": "Elementos da interface do usuário",
                    "Avatars": "Avatares",
                    "Breadcrumb": "Breadcrumb",
                    "Grid": "Grade",
                    "Helper-Classes": "Classes Auxiliares",
                    "List": "Lista",
                    "Ribbons": "Fitas",
                    "Shadow": "Sombra",
                    "Spinner": "Girador",
                    "State-color": "Estado-cor",
                    "Steps": "Passos",
                    "Tag and Pills": "Tag e comprimidos",
                    "Typography": "Tipografia",
                    "Base": "Base",
                    "Accordion": "Acordeão",
                    "Alert": "Alerta",
                    "Buttons": "Botões",
                    "Carousel": "Carrossel",
                    "Collapse": "Colapso",
                    "Datepicker": "Datepicker",
                    "Dropdown": "Suspenso",
                    "Modal": "Modal",
                    "Pagination": "Paginação",
                    "Popover": "Popover",
                    "Progressbar": "Barra de progresso",
                    "Rating": "Avaliação",
                    "Tabset": "Tabset",
                    "Timepicker": "Timepicker",
                    "Tooltip": "Tooltip",
                    "Typeahead": "Typeahead",
                    "Advance": "Avançar",
                    "Drag and Drop": "Arraste e solte",
                    "Dropzone": "Ponto de largada",
                    "Image Cropper": "Cropper de imagem",
                    "Ngx Toastr": "Ngx Toastr",
                    "Owl-Carousel": "Carrossel da Coruja",
                    "Range Slider": "Faixa Deslizante",
                    "Scrollable": "Rolável",
                    "Sticky": "Pegajoso",
                    "SweetAlert": "SweetAlert",
                    "Tour": "Tour",
                    "Upload": "Envio",
                    "Icons": "Ícones",
                    "Flag icon": "Bandeira, ícone",
                    "Fontawesome Icon": "Ícone do Fontawesome",
                    "Ico Icon": "Ícone Ico",
                    "Themify Icon": "Themify Icon",
                    "Feather Icon": "Ícone de penas",
                    "Whether Icon": "Se ícone",
                    //"Buttons": "Botões",
                    "Default Style": "Estilo Padrão",
                    "Flat Style": "Flat Style",
                    "Edge Style": "Estilo de borda",
                    "Raised Style": "Estilo levantado",
                    "Button Group": "Grupo de botões",
                    "Forms": "Formulários",
                    "Form Controls": "Controles de Formulário",
                    "Form Validation": "Validação de Formulário",
                    "Base Inputs": "Entradas Base",
                    "Checkbox & Radio": "Caixa de seleção e rádio",
                    "Input Groups": "Grupos de entrada",
                    "Mega Options": "Mega Opções",
                    "Form Default": "Padrão de formulário",
                    "Ngx Wizard": "Ngx Wizard",
                    "Tables": "Tabelas",
                    "Bootstrap Tables": "Tabelas de Bootstrap",
                    "Basic Table": "Mesa Básica",
                    "Sizing Table": "Tabela de dimensionamento",
                    "Border Table": "Tabela de fronteira",
                    "Styling Table": "Tabela de denominação",
                    "Ngx-Datatables": "Ngx-Datatables",
                    //"Basic Table": "Mesa Básica",
                    "Editing Table": "Tabela de Edição",
                    "Filter Table": "Tabela de filtro",
                    "Fullscreen Table": "Tabela de tela cheia",
                    "Paging Table": "Tabela de Paginação",
                    "Selection": "Seleção",
                    "Smart Table": "Mesa Inteligente",
                    "Cards": "Postais",
                    "Basic Card": "Cartão Básico",
                    "Creative Card": "Cartão Criativo",
                    "Tabbed Card": "Cartão com abas",
                    "Draggable Card": "Cartão Draggable",
                    "Timeline": "Timeline",
                    "Timeline 1": "Linha do tempo 1",
                    "Timeline 2": "Linha do tempo 2",
                    "Charts": "Gráficos",
                    "Google Chart": "Google Chart",
                    "Chartjs": "Chartjs",
                    "Chartist": "Cartista",
                    "Ngx-Chart": "Gráfico Ngx",
                    "Map": "Mapa",
                    "Google Map": "Google Map",
                    "Leaflet Map": "Mapa do folheto",
                    "Editor": "editor",
                    "Users": "Comercial",
                    "Users Profile": "Perfil dos Usuários",
                    "Users Edit": "Usuários Editar",
                    "Users Cards": "Cartões de Usuários",
                    "Calender": "Calendário",
                    "Blog": "Blog",
                    "Blog Details": "Detalhes do Blog",
                    "Blog Single": "Único Blog",
                    "Email": "O email",
                    "Chat": "Bate-papo",
                    "Social App": "App Social",
                    "Job-Search": "Procura de emprego",
                    "Card View": "Visualizar cartão",
                    "List View": "Exibição de lista",
                    "Job Detail": "Detalhe do trabalho",
                    "Apply": "Aplique",
                    "Learning": "Aprendendo",
                    "Learning List": "Lista de aprendizado",
                    "Detail Course": "Curso Detalhado",
                    "FAQ": "Perguntas frequentes",
                    "Knowledgebase": "Base de Conhecimento",
                    "Support Ticket": "Bilhete de Suporte",
                    "To-Do": "Façam",
                    "Ecommerce": "Comércio eletrônico",
                    "Product": "produtos",
                    "Product page": "Página do produto",
                    "Product list": "Lista de produtos",
                    "Payment Details": "Detalhes do pagamento",
                    "Invoice": "Fatura",
                    "Order History": "Histórico de pedidos",
                    "Pricing": "Preços",
                    "Sample page": "Página de exemplo",
                    "Search Pages": "Páginas de pesquisa",
                    "Error Page": "Página de erro",
                    "Error 400": "Erro 400",
                    "Error 401": "Erro 401",
                    "Error 403": "Erro 403",
                    "Error 404": "Erro 404",
                    "Error 500": "Erro 500",
                    "Error 503": "Erro 503",
                    "Authentication": "Autenticação",
                    "Login Simple": "Login simples",
                    "Login with Bg Image": "Faça o login com a imagem Bg",
                    "Login with Bg video": "Entrar com vídeo Bg",
                    "Register Simple": "Cadastre-se simples",
                    "Register with Bg Image": "Registre-se com a imagem Bg",
                    "Register with Bg video": "Registre-se com vídeo Bg",
                    "Unlock User": "Desbloquear usuário",
                    "Forget Password": "Esqueceu a senha",
                    "Reset Password": "Redefinir Senha",
                    "Coming Soon": "Em breve",
                    "Coming Simple": "Chegando Simples",
                    "Coming with Bg Image": "Vindo com imagem Bg",
                    "Coming with Bg video": "Vindo com vídeo Bg",
                    "Maintenance": "Manutenção"
                }
            }
        },
        fallbackLng: "en",
        debug: false,

        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
