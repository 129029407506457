import React, {Fragment, useEffect, useState} from "react";
import Header from "./common/header-component/header";
import Sidebar from "./common/sidebar-component/sidebar";
import RightSidebar from "./common/right-sidebar";
import Footer from "./common/footer";
import { ToastContainer } from "react-toastify";
// import Loader from "./common/loader";
import {Outlet, useMatch} from "react-router-dom";

const AppLayout = (props) => {
    const [authenticated, setAuthenticated] = useState(false);
    const isLoginPage= useMatch("/login")

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            setAuthenticated(true);
        } else {
            setAuthenticated(false);
        }
    }, [ localStorage.token ]);

    return (
    <Fragment>
      {/* <Loader /> */}
      <div className="page-wrapper">
        <div className="page-body-wrapper">
        { authenticated && <Header /> }
          <Sidebar />
          <RightSidebar />
          <div className={`page-body${isLoginPage ? ' login' : ''}`}>
            <Outlet />
          </div>
        { authenticated && <Footer /> }
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default AppLayout;
