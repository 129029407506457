import React, { Suspense } from "react";
import { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AppLayout from "../components/app";
import { Loader } from "react-feather";

import configDB from "../data/customizer/config";
import Login from "../components/common/login";
import PointHistory from "../components/dashboard/point/pointHistory";
import UserList from "../components/dashboard/user/userList";
import OrderList from "../components/dashboard/order/orderList";
import ResellerOrder from "../components/dashboard/order/resellerOrder";
import {useDispatch, useSelector} from "react-redux";
import {setIsLoggedIn, setUserInfo} from "../redux/user/reducer";
import {useQuery} from "react-query";
import {api} from "../utils/api";

const AuthRoute = ({ children }) => {
  const dispatch = useDispatch();

  let isLoggedIn;

  if(localStorage.getItem("isLoggedIn") === 'true') {
    dispatch(setIsLoggedIn(true));
    isLoggedIn = true;
  } else {
    dispatch(setIsLoggedIn(false));
    isLoggedIn = false;
  }

  const { data: user, isStale, isLoading, refetch } = useQuery({
    queryKey: 'user',
    queryFn: async () => {
      const response = await api(localStorage.auth_data).get('/v1/user/checkLogin')

      return response.data.data
    },
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60,
    enabled: false,
  })

  if(isLoggedIn) {
    if(localStorage.auth_data) {
      isStale && refetch();
      dispatch(setUserInfo(user));
    } else {
      isLoggedIn = false;
    }
  }

  return isLoggedIn ? children : <Navigate to={`${process.env.PUBLIC_URL}/login`} />;
}

const MainRoutes = () => {
  useEffect(() => {
    const abortController = new AbortController();
    const color = localStorage.getItem("color");
    const layout = localStorage.getItem("layout_version") || configDB.data.color.layout_version;
    document.body.classList.add(layout);
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/${color}.css`);

    return function cleanup() {
      abortController.abort();
    };
  }, []);

  return (
    <>
      <BrowserRouter basename="/">
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route exact path={`${process.env.PUBLIC_URL}`} element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard/user`} />} />
            <Route element={<AppLayout />}>
              <Route path={`${process.env.PUBLIC_URL}/login`} element={<Login />} />
              <Route path={`${process.env.PUBLIC_URL}/dashboard/user`} component={<AppLayout />} element={<AuthRoute><UserList /></AuthRoute>} />
              <Route path={`${process.env.PUBLIC_URL}/dashboard/order`} component={<AppLayout />} element={<AuthRoute><OrderList /></AuthRoute>} />
              <Route path={`${process.env.PUBLIC_URL}/dashboard/resellerOrder`} component={<AppLayout />} element={<AuthRoute><ResellerOrder /></AuthRoute>} />
              <Route path={`${process.env.PUBLIC_URL}/dashboard/pointHistory`} component={<AppLayout />} element={<AuthRoute><PointHistory /></AuthRoute>} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export default MainRoutes;
