import React, { useState, Fragment, useEffect, useCallback } from 'react';
import { MENUITEMS } from '../../../components/common/sidebar-component/menu';
import { Link } from 'react-router-dom';
import { Search } from 'react-feather';

const SearchHeader = () => {

    const mainmenu = MENUITEMS;
    const [searchValue, setsearchValue] = useState('');
    const [searchOpen, setsearchOpen] = useState(false);

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            //Do whatever when esc is pressed
            setsearchOpen(false)
            setsearchValue('')
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    });

    const handleSearchKeyword = (keyword) => {
        keyword ? addFix() : removeFix()
        const items = [];
        setsearchValue(keyword)
        mainmenu?.filter(Items => {
            if (Items.title.toLowerCase().includes(keyword) && Items.type === 'link') {
                items.push(Items);
            }
            if (!Items.children) return false
            Items?.children?.filter(subItems => {
                if (subItems.title.toLowerCase().includes(keyword) && subItems.type === 'link') {
                    subItems.icon = Items.icon
                    items.push(subItems);
                }
                if (!subItems.children) return false
                subItems?.children?.filter(suSubItems => {
                    if (suSubItems.title.toLowerCase().includes(keyword)) {
                        suSubItems.icon = Items.icon
                        items.push(suSubItems);
                    }
                    return suSubItems
                })
                return subItems
            })
            checkSearchResultEmpty(items)
            setsearchValue(items);
            return Items
        });
    }

    const checkSearchResultEmpty = (items) => {
        if (!items.length) {
            document.querySelector(".empty-menu").classList.add('is-open');
        } else {
            document.querySelector(".empty-menu").classList.remove('is-open');
        }
    }

    const addFix = () => {
        document.querySelector(".Typeahead-menu").classList.add('is-open');
        document.body.classList.add("offcanvas");
    }

    const removeFix = () => {
        setsearchValue('')
        document.querySelector(".Typeahead-menu").classList.remove('is-open');
        document.body.classList.remove("offcanvas");
    }

    const toggleBtn = () => {
        if (searchOpen) {
            setsearchOpen(!searchOpen);
            document.querySelector('.searchIcon').classList.add('open');
        } else {
            setsearchOpen(!searchOpen);
            document.querySelector('.searchIcon').classList.remove('open');
        }
    }


    return (
        <Fragment>
            <div>

            </div>
        </Fragment>
    );
};

export default SearchHeader;