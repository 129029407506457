import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import {Database, Edit, User} from 'react-feather';
import {useSelector} from "react-redux";

const UserPanel = () => {
    const user = useSelector(state => state.UserReducer.user);
    const userName = user?.email;
    let role = '';
    const isAdmin = user?.is_admin;
    const isMerchant = user?.is_merchant;
    const isReseller = user?.is_reseller;
    const balance = new Intl.NumberFormat('ko-KR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format((user?.balance || 0) / 100);

    if(isAdmin) {
        role = '관리자';
    }
    if(isMerchant) {
        role = '판매자';
    }
    if(isReseller) {
        role = '대리판매자';
    }

    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <div>
                    <div className="img-60 rounded-circle bg-white d-flex justify-content-center align-items-center me-auto ms-auto" style={{ aspectRatio: '1 / 1'}}>
                        <User size={26} />
                    </div>
                    {/*<div className="profile-edit">
                        <Link to={`${process.env.PUBLIC_URL}/users/userEdit`}>
                            <Edit />
                        </Link>
                    </div>*/}
                </div>
                <h6 className="mt-3 f-14 overflow-hidden text-nowrap" style={{ textOverflow: 'ellipsis' }}>{userName}</h6>
                <span className="f-14 text-light">{role}</span>
                <span className="f-14 text-light" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 5 }}><Database size={16} style={{ marginRight: 3 }}/> {balance}</span>
            </div>
        </Fragment>
    );
};

export default UserPanel;