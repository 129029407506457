import {
    SET_IS_LOGGED_IN,
    SET_USER_INFO
} from "../../redux/actionTypes";

const initial_state = {
    isLoggedIn: false,
    user: {},
};

export const setIsLoggedIn = isLoggedIn => ({ type: SET_IS_LOGGED_IN, isLoggedIn });
export const setUserInfo = userInfo => ({ type: SET_USER_INFO, userInfo: userInfo });

const userReducer = (state = initial_state, action) => {
    switch (action.type) {
        case SET_IS_LOGGED_IN:
            return { ...state, isLoggedIn: action.isLoggedIn };
        case SET_USER_INFO:
            return { ...state, user: action.userInfo };

        default: return { ...state };
    }
}
export default userReducer