import React, { useState, createRef } from "react";
import {Form } from "react-bootstrap";
import {Button, Card, CardBody, CardHeader, CardTitle, FormGroup, Label} from "reactstrap";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../assets/scss/login.scss';
import {Helmet} from "react-helmet";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {setIsLoggedIn, setUserInfo} from "../../redux/user/reducer";

const Login = () => {
    const navigate = useNavigate();
    const isLoggedIn = useSelector(state => !!state.isLoggedIn);
    const dispatch = useDispatch();

    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");

    const emailRef = createRef();
    const passwordRef = createRef();

    // 로그인 처리
    const handleLogin = async (e) => {
        e.preventDefault();

        if(!email) {
            toast("아이디를 입력하세요.");
            emailRef?.current.focus();
            return;
        }

        if(!password) {
            toast("비밀번호를 입력하세요.");
            passwordRef?.current.focus();
            return;
        }

        try {
            const response = await axios.post('https://webapi.fastn.co.kr/api/v1/passport/auth/login', {
                email,
                password,
            })

            if(response.data.error) {
                toast.error(response.data.error);
                return false;
            }

            if(!response.data.data.is_admin && !response.data.data.is_merchant && !response.data.data.is_reseller) {
                toast.error("판매자만 접근 가능합니다.");
                return false;
            }

            dispatch(setIsLoggedIn(true));
            dispatch(setUserInfo({
                email,
                balance: response.data.data.balance,
                is_admin: response.data.data.is_admin === 1,
                is_merchant: response.data.data.is_merchant === 1,
                is_reseller: response.data.data.is_reseller === 1,
            }));

            window.localStorage.setItem('token', response.data.data.token);
            window.localStorage.setItem('auth_data', response.data.data.auth_data);
            window.localStorage.setItem('isLoggedIn', 'true');

            setTimeout(() => {
                navigate('/dashboard/user')
            }, 0);
        } catch(error) {
            if (axios.isAxiosError(error)) {
                toast.error(error.response?.data.message)
            }
        }
    }

    return (
    <>
        <Helmet>
            <title>로그인</title>
        </Helmet>
        <Card style={{
            marginLeft: "auto",
            marginRight: "auto",
            minWidth: "320px",
        }}>
            <CardHeader>
                <CardTitle>
                    <h1 className="h2" style={{ textAlign: 'center' }}>로그인</h1>
                </CardTitle>
            </CardHeader>
            <CardBody>
                <Form onSubmit={handleLogin}>
                    <FormGroup className="mb-2" row>
                        <Label htmlFor="email">아이디</Label>
                        <Form.Control id="email" ref={emailRef} type="text" placeholder="아이디를 입력하세요." onChange={(e) => setEmail(e.target.value)} />
                    </FormGroup>
                    <FormGroup className="mb-2" row>
                        <Label htmlFor="password">비밀번호</Label>
                        <Form.Control type="password" id="password" ref={passwordRef} placeholder="비밀번호를 입력하세요." onChange={(e) => setPassword(e.target.value)} />
                    </FormGroup>
                    <div className="row">
                        <div className="col" style={{
                            '--bs-gutter-x': '0',
                        }}>
                            <Button type="submit" block>로그인</Button>
                        </div>
                    </div>
                </Form>
            </CardBody>
        </Card>
        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            closeOnClick
            pauseOnHover={false}
            theme="light"
        />
    </>
    )
}

export default Login;